









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface ISimpleSynthesisInputs {
  yield: number;
}

export default defineComponent({
  name: 'LabSimpleSynthesis',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    yield: {
      type: Number as PropType<number>,
      default: 0.97,
      validator(value: number): boolean {
        return value >= 0 && value <= 1;
      },
    },
  },
  computed: {
    labData(): ISimpleSynthesisInputs {
      return {
        yield: this.yield,
      };
    },
    stringifiedData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
