




import LabSimpleSynthesis from '../simulations/LabSimpleSynthesis.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import TaskState from '@/task-states/models/TaskState';
import {VariableValueType} from '@/task-states/types/RawTaskVariableValue';
import LabTask from './labs/LabTask.vue';

export type TaskStateType = TaskState<{yield: VariableValueType.NumberValue}>;

export default defineComponent({
  name: 'SimpleSynthesisSIM',
  components: {LabSimpleSynthesis, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    typedState(): TaskStateType {
      return this.taskState as TaskStateType;
    },
    yield(): number {
      return this.typedState.getValueByName('yield')?.content?.toFloat() as number;
    },
  },
});
